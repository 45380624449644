<template>
  <div class="review-settings">
    <GlobalInfoBar
      title="审单商家列表"
      content="可在此处设置和查看审单报价的回收商商家和管理订单的审单异常项及应用的门店商家"
    />
    <div class="merchant-list">
      <div class="title">审单商家列表</div>
      <div class="flex flex-je">
        <el-button size="small" type="primary" @click="openAddMerchantDia"
          >添加回收商</el-button
        >
      </div>
      <div>
        <el-form ref="merchantForm" :model="merchantForm" inline size="small">
          <el-form-item label="回收商" prop="merchantId">
            <el-select
              v-model="merchantForm.merchantId"
              clearable
              filterable
              placeholder="请输入回收商名称查询"
            >
              <el-option
                v-for="item in merchantList"
                :key="item.merchantId"
                :label="item.merchantName"
                :value="item.merchantId"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="审单状态" prop="isOpenExamineQuote">
            <el-select
              v-model="merchantForm.isOpenExamineQuote"
              clearable
              placeholder="请选择审单状态查询"
            >
              <el-option label="开启" :value="true" />
              <el-option label="关闭" :value="false" />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" round :loading="merchantLoading" @click="searchMerchant(1)"
              >查询</el-button
            >
          </el-form-item>
        </el-form>
        <GlobalTable
          ref="GlobalTable"
          v-loading="merchantLoading"
          isIndex
          :columns="merchantColumns"
          :data="merchantDataList"
          :pageSizes="[10,20,50]"
          :currentPage="merchantForm.pageNum"
          :pageSize="merchantForm.pageSize"
          :total="merchantForm.total"
          layout="total,sizes, prev, pager, next, jumper"
          @handleCurrentChange="searchMerchant"
          @handleSizeChange="handleMerchantSizeChange"
        >
          <el-table-column
            label="添加人"
            slot="openExamineQuoteAdmin"
            align="center"
          >
            <template slot-scope="{ row }">
              {{ row.openExamineQuoteAdmin }}-{{ row.openExamineQuoteAdminAcc }}
            </template>
          </el-table-column>
          <el-table-column
            label="添加时间"
            slot="openExamineQuoteTime"
            align="center"
          >
            <template slot-scope="{ row }">
              {{ row.openExamineQuoteTime || "-" }}
            </template>
          </el-table-column>
          <el-table-column label="商家状态" slot="isEnable" align="center">
            <template slot-scope="{ row }">
              <span :style="!row.isEnable ? 'color:#ff8080;' : ''">{{
                !row.isEnable ? "禁用" : "启用"
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="审单状态" slot="isOpenExamineQuote" align="center">
            <template slot-scope="{ row }">
              <el-switch
                inactive-color="#bebebe"
                @change="handleStatusSwitch(row)"
                v-model="row.isOpenExamineQuote"
              ></el-switch>
            </template>
          </el-table-column>
          <el-table-column label="操作" slot="operate" align="center">
            <template slot-scope="{ row }">
              <el-button size="small" type="primary" @click="openConfigDia(row)">管理审单功能权限</el-button>
            </template>
          </el-table-column>
        </GlobalTable>
      </div>
    </div>
    <el-dialog
      title="回收商列表"
      :visible.sync="addMerchantDiaShow"
      :close-on-click-modal="false"
      width="1200px"
      top="2%"
      @closed="addMerchantDiaClose"
    >
      <div style="display:flex;justify-content: space-between;">
        <div style="width: 630px;margin-right:20px">
          <el-form :inline="true" :model="diaMerchantForm">
            <el-form-item label="回收商" prop="merchantId">
              <el-select
                size="small"
                v-model="diaMerchantForm.merchantId"
                clearable
                filterable
                placeholder="请输入回收商名称查询"
              >
                <el-option
                  v-for="item in merchantList"
                  :key="item.merchantId"
                  :label="item.merchantName"
                  :value="item.merchantId"
                />
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button
                size="small"
                type="primary"
                @click="dialogSearchMerchant(1)"
                >查询</el-button
              >
            </el-form-item>
          </el-form>
          <el-table
            v-if="addMerchantDiaShow"
            ref="addMerchantDia"
            :data="dialogTableData"
            v-loading="merchantDiaLoading"
            tooltip-effect="dark"
            border
            max-height="540"
            @selection-change="handSelect"
            :row-key="
              (row) => {
                return row.merchantId;
              }
            "
          >
            <el-table-column
              type="selection"
              :reserve-selection="true"
              align="center"
            >
            </el-table-column>
            <el-table-column
              type="index"
              align="center"
              label="序号"
              width="50"
              :index="
                (index) => {
                  return (
                    index +
                    1 +
                    (diaMerchantForm.pageNum - 1) * diaMerchantForm.pageSize
                  );
                }
              "
            />
            <el-table-column label="回收商名称" align="center">
              <template slot-scope="{ row }">{{
                row.merchantName || "--"
              }}</template>
            </el-table-column>
            <el-table-column label="联系人" align="center">
              <template slot-scope="{ row }">{{
                row.contactName || "--"
              }}</template>
            </el-table-column>
            <el-table-column label="联系电话" align="center">
              <template slot-scope="{ row }">{{
                row.contactPhone || "--"
              }}</template>
            </el-table-column>
          </el-table>
          <el-pagination
            style="text-align: right; margin-top: 20px"
            @current-change="dialogSearchMerchant"
            :current-page="diaMerchantForm.pageNum"
            layout="total,  prev, pager, next, jumper"
            :total="diaMerchantForm.total"
            :background="true"
          >
          </el-pagination>
        </div>
        <div style="width: 520px;">
          <p style="margin-bottom:20px">
            已选商户({{ selectedMerchantList.length }})
          </p>
          <el-table
            ref="addtipleTable"
            :data="selectedMerchantList"
            tooltip-effect="dark"
            border
            max-height="640"
            style="width: 100%"
          >
            <el-table-column
              type="index"
              align="center"
              label="序号"
              width="50"
            >
            </el-table-column>
            <el-table-column label="回收商名称" align="center">
              <template slot-scope="{ row }">{{
                row.merchantName || "--"
              }}</template>
            </el-table-column>
            <el-table-column label="联系人" align="center">
              <template slot-scope="{ row }">{{
                row.contactName || "--"
              }}</template>
            </el-table-column>
            <el-table-column label="联系电话" align="center">
              <template slot-scope="{ row }">{{
                row.contactPhone || "--"
              }}</template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="{ row }">
                <p
                  @click="detaleCompy(row)"
                  style="color:red;cursor: pointer;text-decoration:underline"
                >
                  删除
                </p>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click.native="
            addMerchantDiaShow = false;
            selectedMerchantList = [];
          "
          >取消</el-button
        >
        <el-button type="primary" @click.native="addMerchantSubmit"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="安全验证"
      :visible.sync="commandDisableShow"
      :close-on-click-modal="false"
      width="500px"
      @closed="commandClosed"
    >
      <div v-if="tipExplain" style="margin-bottom: 20px;">
        <span style="color: #ff8695;font-size: 14px;">{{ tipExplain }}</span>
      </div>
      <div class="tip-centent">
        <span>{{ commandTipText }}</span>
      </div>
      <div class="command">
        <div style="width: max-content">谷歌验证码：</div>
        <el-input
          v-model="command"
          placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="commandDisableShow = false">取消</el-button>
        <el-button
          type="primary"
          @click.native="commandSureAdd"
          :loading="commandButLoading"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="订单过滤最大时效"
      :visible.sync="ageingDiaShow"
      width="550px"
      :close-on-click-modal="false"
      @closed="command = ''"
    >
      <p class="tip-txt mb-10">
        说明：设置后，商户能过滤的订单时效最高值为该设定值
      </p>
      <div class="flex" style="margin-top: 10px;align-items: flex-start;">
        <span class="label-S" style="min-width: 70px;"><span class="label-must">*</span>时效过滤上限：</span>
        <div>
          <div>
            <span>此商户最多可过滤小于</span>
            <el-input-number
              style="margin: 0 10px;"
              :precision="0"
              size="small"
              v-model="time"
              :min="0"
              :max="99999"
              @blur="time = time || 0"
            ></el-input-number>
            <span>秒的订单</span>
          </div>
          <div style="margin-top: 10px;color: #999;font-size: 14px;">此处需填入区间为10≤报价时效≤60，填入整数</div>
        </div>
      </div>
      <div class="flex" style="margin-top: 10px;align-items: flex-start;">
        <span class="label-S" style="min-width: 70px;flex-shrink: 0;"><span class="label-must">*</span>谷歌验证码：</span>
        <el-input
          v-model="command"
          placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="ageingDiaShow = false">取 消</el-button>
        <el-button
          type="primary"
          :loading="ageingDiaBtnLoading"
          @click="ageingDiaSure"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="抢单顺序设置"
      :visible.sync="examineNextSortVisible"
      :close-on-click-modal="false"
      width="500px"
      @closed="command = ''"
    >
      <p style="color: #ff8695;font-size: 14px;">说明：抢单顺序即为审单员拿取下一单时的顺序，越早加入队列的订单，审单报价时效会越短</p>
      <el-form label-width="100px">
        <el-form-item label="选择顺序" required>
          <el-radio-group v-model="examineNextSort">
            <el-radio label="00">最早加入队列</el-radio>
            <el-radio label="01">最晚加入队列</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="谷歌验证码" required>
          <el-input v-model="command" placeholder="请输入谷歌验证码"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="examineNextSortVisible = false">取消</el-button>
        <el-button
          type="primary"
          :loading="examineNextSortBtnLoading"
          @click.native="examineNextSortSure"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="管理审单功能权限"
      :visible.sync="configDiaVisible"
      :close-on-click-modal="false"
      width="500px"
    >
      <GlobalTable
        ref="GlobalTable"
        v-loading="configDiaLoading"
        :columns="configTableColumns"
        :data="configDataList"
        :isPagination="false"
      >
        <el-table-column label="功能情况" slot="functionInfo" align="center">
          <template slot-scope="{ row }">
            <template v-if="row.key === 'examineFilterMaxSeconds'">
              <div>{{currentMerchant.examineFilterMaxSeconds}}S</div>
              <span class="txt-underline" @click="examineFilterMaxSecondsSet(currentMerchant)">设置</span>
            </template>
            <template v-if="row.key === 'examineNextSort'">
              <div>{{currentMerchant.examineNextSort =='00' ?'最早加入队列':currentMerchant.examineNextSort =='01'?'最晚加入队列':'--'}}</div>
              <span class="txt-underline" @click="openExamineNextSortDia(currentMerchant)">设置</span>
            </template>
            <template v-if="row.key === 'isOpenExamineQuoteWatermark'">
              <el-switch
                inactive-color="#bebebe"
                @change="handleSwitch"
                v-model="currentMerchant.isOpenExamineQuoteWatermark"
              ></el-switch>
            </template>
            <template v-if="row.key === 'isExamineUnStart'">
              <el-switch
                inactive-color="#bebebe"
                @change="handleStartSwitch"
                v-model="currentMerchant.isExamineUnStart"
              ></el-switch>
            </template>
            <template v-if="row.key === 'isEnableWechatExamine'">
              <el-switch
                inactive-color="#bebebe"
                @change="handleXcxSwitch"
                v-model="currentMerchant.isEnableWechatExamine"
              ></el-switch>
            </template>
            <template v-if="row.key === 'isExamineRejectQuote'">
              <el-switch
                inactive-color="#bebebe"
                @change="handleRefuseSwitch"
                v-model="currentMerchant.isExamineRejectQuote"
              ></el-switch>
            </template>
            <template v-if="row.key === 'isExamineDiffLeave'">
              <el-switch
                inactive-color="#bebebe"
                @change="handleDiffSwitch"
                v-model="currentMerchant.isExamineDiffLeave"
              ></el-switch>
            </template>
            <template v-if="row.key === 'isTransExamineLeader'">
              <el-switch
                inactive-color="#bebebe"
                @change="handleTransSwitch"
                v-model="currentMerchant.isTransExamineLeader"
              ></el-switch>
            </template>
            <template v-if="row.key === 'isMiniExamineTimelyPull'">
              <el-switch
                inactive-color="#bebebe"
                @change="handleWsSwitch"
                v-model="currentMerchant.isMiniExamineTimelyPull"
              ></el-switch>
            </template>
          </template>
        </el-table-column>
      </GlobalTable>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click.native="configDiaVisible = false">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _api from "@/utils/request";
export default {
  data() {
    return {
      merchantLoading: false,
      merchantForm: {
        merchantId: "",
        isOpenExamineQuote: "",
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      merchantList: [],
      merchantDataList: [],
      merchantColumns: [
        { label: "回收商名称", prop: "merchantName" },
        { label: "联系人", prop: "contactName" },
        { label: "联系电话", prop: "contactPhone" },
        { slotName: "openExamineQuoteAdmin" },
        { slotName: "openExamineQuoteTime" },
        { slotName: "isEnable" },
        // { slotName: "examineFilterMaxSeconds" },
        // { slotName: "examineNextSort" },
        // { slotName: "isOpenExamineQuoteWatermark" },
        // { slotName: "isExamineUnStart" },
        // { slotName: "isEnableWechatExamine" },
        // { slotName: "isExamineRejectQuote" },
        { slotName: "isOpenExamineQuote" },
        { slotName: "operate" },
      ],
      addMerchantDiaShow: false,
      merchantDiaLoading: false,
      dialogTableData: [],
      selectedMerchantList: [],
      diaMerchantForm: {
        merchantId: "",
        isOpenExamineQuote: "",
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      tableMerchantColumns: [
        { label: "回收商名称", prop: "merchantName" },
        { label: "联系人", prop: "contactName" },
        { label: "联系电话", prop: "contactPhone" },
      ],
      // 安全验证
      commandTipText: "",
      tipExplain: "",
      commandType: "",
      commandDisableShow: false,
      command: "",
      commandButLoading: false,
      rowObj: {}, //操作当前行
      // 审单报价过滤最大时效弹框
      ageingDiaShow: false,
      time: "",
      ageingDiaBtnLoading: false,
      examineNextSortVisible: false,
      examineNextSortBtnLoading: false,
      examineNextSort: "",
      currentMerchant: {},
      configDiaVisible: false,
      configTableColumns: [
        { label: "功能名称", prop: "configName" },
        { slotName: "functionInfo" },
      ],
      configDiaLoading: false,
      configDataList: [
        {configName: "订单最大过滤时效",key:'examineFilterMaxSeconds'},
        {configName: "优先报价顺序",key:'examineNextSort'},
        {configName: "水印状态",key:'isOpenExamineQuoteWatermark'},
        {configName: "无法开机推单",key:'isExamineUnStart'},
        {configName: "小程序审单",key:'isEnableWechatExamine'},
        {configName: "无法报价",key:'isExamineRejectQuote'},
        {configName: "差异预留",key:'isExamineDiffLeave'},
        {configName: "审单转组长",key:'isTransExamineLeader'},
        {configName: "小程序审单即时推送",key:'isMiniExamineTimelyPull'},
      ],
    };
  },
  created() {
    this.searchMerchant(1);
    this.getMerchantList();
  },
  methods: {
    getMerchantList() {
      _api.getMerchantSelectList().then((res) => {
        this.merchantList = res.data;
      });
    },
    // 查询商家
    searchMerchant(pageNum) {
      this.merchantLoading = true;
      _api
        .examineQuoteList({
          ...this.merchantForm,
          pageNum: pageNum || this.merchantForm.pageNum,
        })
        .then((res) => {
          this.merchantDataList = res.data.records;
          this.merchantForm.pageNum = res.data.current;
          this.merchantForm.total = res.data.total;
        })
        .finally(() => {
          this.merchantLoading = false;
        });
    },
    handleMerchantSizeChange(num) {
      this.merchantForm.pageSize = num
      this.searchMerchant(1)
    },
    // 审单状态
    handleStatusSwitch(row) {
      this.commandTipText = row.isOpenExamineQuote?'是否确认开启审单功能？':'是否确认关闭审单功能？';
      this.commandType = "examineStatus";
      this.commandDisableShow = true;
      this.rowObj = row
    },
    // 水印状态切换
    handleSwitch() {
      this.commandTipText = this.currentMerchant.isOpenExamineQuoteWatermark?'是否确定开启该商家水印？':'是否确定关闭该商家水印？';
      this.commandType = "water";
      this.commandDisableShow = true;
    },
    handleStartSwitch() {
      this.commandTipText = this.currentMerchant.isExamineUnStart?'是否确定开启该商家无法开机推单？':'是否确定关闭该商家无法开机推单？';
      this.commandType = "start";
      this.tipExplain = this.currentMerchant.isExamineUnStart?"移除后，需在系统管理-菜单管理中隐藏该商家的审单报价菜单":''
      this.commandDisableShow = true;
    },
    handleXcxSwitch() {
      this.commandTipText = this.currentMerchant.isEnableWechatExamine?'是否确认开启小程序审单？':'是否确认关闭小程序审单？';
      this.tipExplain = this.currentMerchant.isEnableWechatExamine?'开启后，商户可以自行选择审单员使用小程序审单还是后台审单，两种模式只可选择一种':'关闭后，审单员需全部使用后台审单，已登录小程序的审单员将被下线';
      this.commandType = "xcx";
      this.commandDisableShow = true;
    },
    handleRefuseSwitch() {
      this.commandTipText = this.currentMerchant.isExamineRejectQuote?'是否确认开启？':'是否确认关闭？';
      this.tipExplain = this.currentMerchant.isExamineRejectQuote?'开启后，审单员审单页面拥有“无法报价”按钮':'关闭后，审单员审单页面没有“无法报价”按钮';
      this.commandType = "reject";
      this.commandDisableShow = true;
    },
    handleDiffSwitch() {
      this.commandTipText = this.currentMerchant.isExamineDiffLeave?'是否确认为回收商开启差异预留功能？':'是否确认为回收商关闭差异预留功能？ ';
      this.tipExplain = this.currentMerchant.isExamineDiffLeave?'开启后，回收商可以预留差异，并且可以导入实际出货价进行核对差异':'关闭后，回收商可以预留差异，并且可以导入实际出货价进行核对差异';
      this.commandType = "diff";
      this.commandDisableShow = true;
    },
    handleTransSwitch() {
      this.commandTipText = this.currentMerchant.isTransExamineLeader?'是否确认为回收商开启审单组长功能？':'是否确认为回收商关闭审单组长功能？ ';
      this.commandType = "trans";
      this.commandDisableShow = true;
    },
    handleWsSwitch() {
      this.commandTipText = this.currentMerchant.isMiniExamineTimelyPull?'是否确认开启小程序审单即时推送功能？':'是否确认关闭小程序审单即时推送功能？ ';
      this.tipExplain = this.currentMerchant.isMiniExamineTimelyPull?'说明：开启后，来单时，会出现来单提示标签':'说明：关闭后，需手动刷新才会审下一单';
      this.commandType = "ws";
      this.commandDisableShow = true;
    },
    // 移除商家、
    delmerchant(row) {
      this.commandTipText = "是否确定移除该商家？";
      this.commandType = "del";
      this.tipExplain = "移除后，需在系统管理-菜单管理中隐藏该商家的审单报价菜单"
      this.commandDisableShow = true;
      this.rowObj = row
    },
    // 打开添加回收商弹框
    openAddMerchantDia() {
      this.dialogSearchMerchant(1);
      this.addMerchantDiaShow = true;
    },
    // 确认添加商家
    addMerchantSubmit() {
      this.commandTipText = "确定添加回收商吗？";
      this.commandType = "add";
      this.commandDisableShow = true;
    },
    // 添加商家弹框查询
    dialogSearchMerchant(pageNum) {
      this.merchantDiaLoading = true;
      _api
        .examineQuoteNonelist({
          ...this.diaMerchantForm,
          pageNum:pageNum || this.diaMerchantForm.pageNum,
        })
        .then((res) => {
          this.dialogTableData = res.data.records;
          this.diaMerchantForm.pageNum = res.data.current;
          this.diaMerchantForm.total = res.data.total;
          this.dialogTableData.forEach((item) => {
            if (!this.selectedMerchantList.some((selectedItem) => selectedItem.merchantId === item.merchantId)) {
                this.$nextTick(()=>{
                    this.$refs.addMerchantDia.toggleRowSelection(item, false);
                })
            }
            console.log(this.selectedMerchantList.length,'this.selectedMerchantList.length');
            if (!this.selectedMerchantList.length) {
              this.$refs.addMerchantDia.clearSelection();
            }
          });
        })
        .finally(() => {
          this.merchantDiaLoading = false;
        });
    },
    // 添加商家弹框关闭
    addMerchantDiaClose() {
      this.diaMerchantForm = {
        merchantId: "",
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      this.commandTipText = "";
      this.addMerchantDiaShow = false;
      this.selectedMerchantList = [];
    },
    //删除
    detaleCompy(row) {
      let flag = true;
      this.dialogTableData.forEach(item => {
        if(item.merchantId === row.merchantId){
          flag = false
          this.$refs.addMerchantDia.toggleRowSelection(item, false);
        }
      })
      if (flag) {
        this.selectedMerchantList = this.selectedMerchantList.filter(item => item.merchantId !== row.merchantId);
      }
    },    handSelect(selection, row) {
      console.log(selection, row, "selection, rowselection, rowselection, row");
      this.selectedMerchantList = selection;
    },
    // 安全验证添加商家
    commandSureAdd() {
      if (!this.command) {
        return this.$message.error("请输入谷歌动态码!");
      }
      this.commandButLoading = true;
      if (this.commandType == "add") {
        let merchantIds = [];
        this.selectedMerchantList.forEach((item) => {
          merchantIds.push(item.merchantId);
        });
        let parame = {
          command: this.command,
          merchantIds,
        };
        _api
          .examineQuoteSaveBatch(parame)
          .then((res) => {
            if (res.code === 1) {
              this.$message.success("添加成功!");
              this.commandDisableShow = false;
              this.addMerchantDiaShow = false;
              this.merchantForm.pageNum = 1
            }
          })
          .finally(() => {
            this.commandButLoading = false;
          });
      } else if (this.commandType == "del") {
        let parame = {
          command: this.command,
          merchantId: this.rowObj.merchantId,
        };
        _api
          .examineQuoteRemove(parame)
          .then((res) => {
            if (res.code === 1) {
              this.$message.success("移除成功!");
              this.commandDisableShow = false;
            }
          })
          .finally(() => {
            this.commandButLoading = false;
          });
      } else if (this.commandType == "water") {
        let parame = {
          command: this.command,
          id: this.rowObj.merchantId,
          isOpen: this.currentMerchant.isOpenExamineQuoteWatermark,
        };
        _api
          .updateIsOpenWatermark(parame)
          .then((res) => {
            if (res.code === 1) {
              this.$message.success("操作成功!");
              this.commandDisableShow = false;
            }
          })
          .finally(() => {
            this.commandButLoading = false;
          });
      } else if (this.commandType == "start") {
        let parame = {
          command: this.command,
          id: this.rowObj.merchantId,
          isOpen: this.currentMerchant.isExamineUnStart,
        };
        _api
          .updateIsExamineUnStart(parame)
          .then((res) => {
            if (res.code === 1) {
              this.$message.success("操作成功!");
              this.commandDisableShow = false;
            }
          })
          .finally(() => {
            this.commandButLoading = false;
          });
      } else if(this.commandType == "xcx") {
        let parame = {
          command: this.command,
          id: this.rowObj.merchantId,
          isOpen: this.currentMerchant.isEnableWechatExamine,
        };
        _api
          .updateIsEnableWechatExamine(parame)
          .then((res) => {
            if (res.code === 1) {
              this.$message.success("操作成功!");
              this.commandDisableShow = false;
            }
          })
          .finally(() => {
            this.commandButLoading = false;
          });
        }else if(this.commandType == "examineStatus") {
        let parame = {
          command: this.command,
          id: this.rowObj.merchantId,
          isOpen: this.rowObj.isOpenExamineQuote,
        };
        _api
          .updateIsOpenExamineQuote(parame)
          .then((res) => {
            if (res.code === 1) {
              this.$message.success("操作成功!");
              this.commandDisableShow = false;
            }
          })
          .finally(() => {
            this.commandButLoading = false;
          });
      }else if(this.commandType == "reject") {
        let parame = {
          command: this.command,
          id: this.rowObj.merchantId,
          isOpen: this.currentMerchant.isExamineRejectQuote,
        };
        _api
          .updateIsExamineRejectQuote(parame)
          .then((res) => {
            if (res.code === 1) {
              this.$message.success("操作成功!");
              this.commandDisableShow = false;
            }
          })
          .finally(() => {
            this.commandButLoading = false;
          });
      }else if(this.commandType == "diff") {
        let parame = {
          command: this.command,
          id: this.rowObj.merchantId,
          isOpen: this.currentMerchant.isExamineDiffLeave,
        };
        _api
          .updateIsExamineDiffLeave(parame)
          .then((res) => {
            if (res.code === 1) {
              this.$message.success("操作成功!");
              this.commandDisableShow = false;
            }
          })
          .finally(() => {
            this.commandButLoading = false;
          });
      }else if(this.commandType == "trans") {
        let parame = {
          command: this.command,
          id: this.rowObj.merchantId,
          isOpen: this.currentMerchant.isTransExamineLeader,
        };
        _api
          .updateIsTransExamineLeader(parame)
          .then((res) => {
            if (res.code === 1) {
              this.$message.success("操作成功!");
              this.commandDisableShow = false;
            }
          })
          .finally(() => {
            this.commandButLoading = false;
          });
      }else if(this.commandType == "ws") {
        let parame = {
          command: this.command,
          id: this.rowObj.merchantId,
          isOpen: this.currentMerchant.isMiniExamineTimelyPull,
        };
        _api
          .updateIsMiniExamineTimelyPull(parame)
          .then((res) => {
            if (res.code === 1) {
              this.$message.success("操作成功!");
              this.commandDisableShow = false;
            }
          })
          .finally(() => {
            this.commandButLoading = false;
          });
      }
    },
    // 安全提示弹框关闭回调
    commandClosed() {
      this.command = ''
      this.tipExplain = ''
      console.log(this.configDiaVisible,'this.configDiaVisible');
      if(!this.configDiaVisible){
        this.searchMerchant();
      }else{
        this.getExamineMerchantInfo()
      }
    },
    examineFilterMaxSecondsSet(row) {
      this.time = row.examineFilterMaxSeconds
      this.rowObj = row
      this.ageingDiaShow = true
    },
    ageingDiaSure() {
      if(this.time < 10){
        this.$message.error('时效过滤上限不可小于10秒')
        return
      }
      if (!this.command) {
        return this.$message.error("请输入谷歌动态码!");
      }
      let parame = {
        merchantId: this.rowObj.merchantId,
        examineFilterMaxSeconds: this.time,
        command: this.command
      }
      this.ageingDiaBtnLoading = true
      this.$confirm(`是否确认此商户最多可过滤小于${this.time}秒的订单`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        _api.setOrderFilterMaxSeconds(parame).then(res => {
          if(res.code === 1){
            this.$message({
              message: '操作成功',
              type: 'success'
            })
            this.ageingDiaShow = false
            this.getExamineMerchantInfo()
          }
        }).finally(() => {
          this.ageingDiaBtnLoading = false;
        });
      }).catch(() => {
        this.ageingDiaBtnLoading = false;         
      });
      
    },
    openExamineNextSortDia(row) {
      this.rowObj = JSON.parse(JSON.stringify(row))
      this.command = ""
      this.examineNextSort = row.examineNextSort
      this.examineNextSortVisible = true
    },
    examineNextSortSure() {
      if (!this.command.trim()) {
        return this.$message.error("请输入谷歌验证码!");
      }
      this.examineNextSortBtnLoading = true
      let params = {
        command: this.command,
        examineNextSort: this.examineNextSort,
        merchantId: this.rowObj.merchantId
      }
      _api.changeExamineNextSort(params).then(res => {
        if(res.code === 1){
          this.$message.success("操作成功!")
          this.examineNextSortVisible = false
          this.getExamineMerchantInfo();
        }
      }).finally(() => {
        this.examineNextSortBtnLoading = false;
      });
    },
    // 打开管理审单功能权限弹框
    openConfigDia(row) {
      this.configDiaVisible = true
      this.rowObj = row
      this.getExamineMerchantInfo()
    },
    getExamineMerchantInfo() {
      this.configDiaLoading = true
      _api.getExamineMerchantInfo({id: this.rowObj.merchantId,}).then(res => {
        if(res.code === 1){
          this.currentMerchant = res.data
          this.configDiaLoading = false
        }
      });
    }
  },
};
</script>
<style scoped lang="scss" ref="stylesheet/scss">
.review-settings {
  .txt-underline{
    color: #0981FF;
    text-decoration: underline;
    cursor: pointer;
  }
  .flex {
    display: flex;
    align-items: center;
  }
  .flex-je {
    display: flex;
    justify-content: flex-end;
  }
  .merchant-list {
    padding: 20px;
    background: white;
    border-radius: 20px;
    .title {
      font-size: 18px;
      color: #333333;
      font-weight: bold;
    }
  }
  .addparto {
    font-size: 12px;
    font-family: FZLanTingHei-M-GBK;
    font-weight: 400;
    color: #ff8080;
  }
  .tip-centent {
    font-size: 16px;
    text-align: center;
    margin-bottom: 20px;
  }
  .command {
    display: flex;
    align-items: center;
    /deep/.el-input {
      width: 300px;
    }
  }
  .tip-txt {
    color: #ff687b;
    font-size: 14px;
  }
  .label-S {
    min-width: 100px;
    text-align: right;
    margin-right: 20px;
    line-height: 32px;
    .label-must {
      color: #ff687b;
      margin-right: 4px;
    }
  }
  .nowrap-ellipsis{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }
}
</style>
